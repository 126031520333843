<template>
  <div>
    <v-dialog v-model="showInfoDialog" width="100%" max-width="100%">
      <v-card color="grey lighten-2">
        <v-card-title class="justify-end ma-0 pa-1">
          <v-btn icon class="pa-0">
            <v-icon @click="showInfoDialog = false">close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div
            v-for="(lane, index) of zoneConfigInfo"
            :key="lane + '-' + index"
          >
            <v-container style="min-width: 100%" class="pa-0">
              <v-card class="my-2" elevation="0" color="#ffffff">
                <v-card-text class="pa-2">
                  <v-row no-gutters>
                    <v-col cols="7">
                      <span class="font-weight-bold black--text">QR code</span>
                    </v-col>
                    <v-col cols="5" class="pl-1">
                      <span>{{
                        Object.hasOwnProperty.call(lane, "qrCode") &&
                        lane.qrCode != "" &&
                        lane.qrCode != null &&
                        lane.qrCode != undefined
                          ? lane.qrCode
                          : "-"
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="7">
                      <span class="font-weight-bold black--text"
                        >Lane Name</span
                      >
                    </v-col>
                    <v-col cols="5" class="pl-1">
                      <span>{{
                        Object.hasOwnProperty.call(lane, "lane_text_current") &&
                        lane.lane_text_current != "" &&
                        lane.lane_text_current != null &&
                        lane.lane_text_current != undefined
                          ? lane.lane_text_current.toUpperCase()
                          : "-"
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="7">
                      <span class="font-weight-bold black--text"
                        >Serial Number</span
                      >
                    </v-col>
                    <v-col cols="5" class="pl-1">
                      <span>{{
                        Object.hasOwnProperty.call(lane, "serial") &&
                        lane.serial != "" &&
                        lane.serial != null &&
                        lane.serial != undefined
                          ? lane.serial
                          : "-"
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="7">
                      <span class="font-weight-bold black--text"
                        >Gate IP Address</span
                      >
                    </v-col>
                    <v-col cols="5" class="pl-1">
                      <span>{{
                        Object.hasOwnProperty.call(lane, "gateIPAddress") &&
                        lane.gateIPAddress != "" &&
                        lane.gateIPAddress != null &&
                        lane.gateIPAddress != undefined &&
                        lane.gateIPAddress != "..."
                          ? lane.gateIPAddress
                          : "-"
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="7">
                      <span class="font-weight-bold black--text"
                        >Card Reader IP Address</span
                      >
                    </v-col>
                    <v-col cols="5" class="pl-1">
                      <span>{{
                        Object.hasOwnProperty.call(
                          lane,
                          "cardReaderIPAddress"
                        ) &&
                        lane.cardReaderIPAddress != "" &&
                        lane.cardReaderIPAddress != null &&
                        lane.cardReaderIPAddress != undefined &&
                        lane.cardReaderIPAddress != "..."
                          ? lane.cardReaderIPAddress
                          : "-"
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="7">
                      <span class="font-weight-bold black--text"
                        >Reversible Loop</span
                      >
                    </v-col>
                    <v-col cols="5" class="pl-1">
                      <span>{{
                        Object.hasOwnProperty.call(lane, "isLoopReversed") &&
                        lane.isLoopReversed == 1
                          ? "Yes"
                          : "No"
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="7">
                      <span class="font-weight-bold black--text"
                        >Gate Type</span
                      >
                    </v-col>
                    <v-col cols="5" class="pl-1">
                      <span>{{
                        Object.hasOwnProperty.call(lane, "gateType") &&
                        lane.gateType != "" &&
                        lane.gateType != null &&
                        lane.gateType != undefined
                          ? lane.gateType.toUpperCase()
                          : "-"
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="7">
                      <span class="font-weight-bold black--text"
                        >2D Scanner</span
                      >
                    </v-col>
                    <v-col cols="5" class="pl-1">
                      <span>{{
                        Object.hasOwnProperty.call(lane, "scannerConfigure") &&
                        lane.scannerConfigure == 1
                          ? "Yes"
                          : "No"
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="7">
                      <span class="font-weight-bold black--text"
                        >Prox Reader</span
                      >
                    </v-col>
                    <v-col cols="5" class="pl-1">
                      <span>{{
                        Object.hasOwnProperty.call(lane, "proxConfigure") &&
                        lane.proxConfigure == 1
                          ? "Yes"
                          : "No"
                      }}</span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-icon
      @click="openLaneInfoDialog()"
      color="red"
      class="material-symbols-outlined"
    >
      info
    </v-icon>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    zoneConfigInfo: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      showInfoDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedZone: "pinaStore/getterSelectedZone",
    }),
  },
  mounted() {
    this.$store.commit("pinaStore/SET_LOADING_STATUS", {
      property: "LANE_INFO_DIALOG",
      pending: false,
    });
  },
  methods: {
    openLaneInfoDialog() {
      this.showInfoDialog = true;
    },
  },
};
</script>