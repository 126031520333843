<template>
  <div>
  <v-container
    fluid
    pa-2
    v-if="!pageLoadingStatus"
  >
    <v-row no-gutters v-if="
      Object.hasOwnProperty.call(zoneEntrancesData, 'entranceList') &&
      zoneEntrancesData.entranceList != null
    ">
      <v-col cols="12">
        <span class="ml-2"
          ><b>{{ Object.keys(zoneEntrancesData.entranceList).length }}</b>
          entrances found
        </span>
      </v-col>
      <v-col cols="12">
        <span class="ml-2">Please click on the button to configure Kiosk</span>
      </v-col>
      <v-col cols="12">
        <v-card
          v-for="entrance in zoneEntrancesData.entranceList"
          :key="entrance.entranceID"
          class="ma-2"
        >
          <v-card-title>
            <span>{{ entrance.name }}</span>
            <v-spacer></v-spacer>
            <lanes-info-dialog
              v-if="getEntranceConfigInfo(entrance.entranceID).length > 0"
              :zoneConfigInfo="getEntranceConfigInfo(entrance.entranceID)"
            ></lanes-info-dialog>
          </v-card-title>
          <v-card-text>
            <v-container fluid pa-0>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="text-center my-2"
                  v-for="gate in entrance['IN']['Lanes']"
                  :key="gate.id"
                >
                  <v-btn
                    block
                    :color="gate.kioskCompletionStatus == 0 ? 'primary':'green'"
                    x-large
                    @click="configureKiosk(gate)"
                  >
                    {{ gate.lane_text_current }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  class="text-center my-2"
                  v-for="gate in entrance['OUT']['Lanes']"
                  :key="gate.id"
                >
                  <v-btn
                    block
                    :color="gate.kioskCompletionStatus == 0 ? 'primary':'green'"
                    x-large
                    @click="configureKiosk(gate)"
                  >
                    {{ gate.lane_text_current }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
    <v-container v-else style="height: 80vh" fluid pa-0>
      <v-row style="height:100%" align-content="center" justify="center">
        <v-col cols="12" class="text-subtitle-1 text-center">
          <v-progress-circular color="primary" indeterminate rounded :size="50"></v-progress-circular>
          <p> Please wait...</p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as API from "@/api/api";
import * as bookingSvcAPI from "@/api/bookingSvcAPI"
import LanesInfoDialog from '../zoneConfig/LanesInfoDialog.vue';
export default {
  name: "Zoneconfig",
  components: {LanesInfoDialog},
  data: () => ({
    zoneEntrancesData: {},
    zoneConfigInfo: [],
    pageLoadingStatus: false,
  }),
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit("pinaStore/REMOVE_APP_STEP", {type:'Zone', text: vm.selectedZone.name,href:"/#/home/"+vm.selectedZone.zid});
    });
  },
  computed: {
    ...mapGetters({
      selectedZone: "pinaStore/getterSelectedZone",
      uid: "authStore/getterUID",
    }),
  },
  watch: {},
  mounted() {
    this.getZoneAndLaneDetails()
  },
  methods: {
    async getZoneAndLaneDetails(){
      this.pageLoadingStatus = true;
      await this.getSelectedZoneSummary();
      await this.getLaneConfigInfo();
      this.pageLoadingStatus = false;
    },
    /**
     * Calling API to fetch zone entrances
     */
    async getSelectedZoneSummary() {
      try {
        const reqData = {
          uid: this.uid,
          zid: this.selectedZone.zid,
        };
        const result = await API.getZoneSummary(reqData);
        this.zoneEntrancesData = Object.assign({}, result.data.newData[0]);
        var resultEntrance = {};
        if (this.zoneEntrancesData.entranceList.length > 0) {
          for (var i in this.zoneEntrancesData.entranceList) {
            var eachZoneEntrance = this.zoneEntrancesData.entranceList[i];
            var PEDESTRIAN = { Lanes: [] };
            var OUT = { Lanes: [] };
            var IN = { Lanes: [] };
            resultEntrance[i] = Object.assign({}, eachZoneEntrance);
            if (eachZoneEntrance["IN"].Lanes.length > 0) {
              eachZoneEntrance["IN"].Lanes.forEach((eachIn) => {
                if (eachIn.isPedestrian == "1") {
                  PEDESTRIAN["Lanes"].push(eachIn);
                } else {
                  IN["Lanes"].push(eachIn);
                }
              });
            }
            if (eachZoneEntrance["OUT"].Lanes.length > 0) {
              eachZoneEntrance["OUT"].Lanes.forEach((eachOut) => {
                if (eachOut.isPedestrian == "1") {
                  PEDESTRIAN["Lanes"].push(eachOut);
                } else {
                  OUT["Lanes"].push(eachOut);
                }
              });
            }
            resultEntrance[i]["PEDESTRIAN"] = PEDESTRIAN;
            resultEntrance[i]["OUT"] = OUT;
            resultEntrance[i]["IN"] = IN;
          }
        }
        this.zoneEntrancesData.entranceList = resultEntrance;
      } catch (error) {
        console.log(error);
      }
    },
    async getLaneConfigInfo(){
      try{
        let reqData = {
          zid: this.selectedZone.zid
        }
        let laneInfo = await bookingSvcAPI.getLaneInfo(reqData);
        if(laneInfo?.data && laneInfo.data?.status && laneInfo.data.status){
          this.zoneConfigInfo = laneInfo.data?.data ? laneInfo.data.data : [];
        }
        this.$store.commit("pinaStore/SET_ZONE_CONFIG_INFO", this.zoneConfigInfo);
      }
      catch (e) {
        console.log(e);
      }
    },
    getEntranceConfigInfo(entranceID){
      return (this.zoneConfigInfo.filter((lane) => lane.entrance_id == entranceID && lane.overallPercentage == 100))
    },
    configureKiosk(gate) {
      this.$store.commit("pinaStore/SET_SELECTED_LANE", gate);
      this.$store.commit("pinaStore/SET_APP_STEP", {type:'Config', text: gate.lane_text_current,href:"/#/home/"+this.selectedZone.zid+ "/" + gate.lane_id});
      this.$router.push({
        path: "/home/" + this.selectedZone.zid + "/" + gate.lane_id,
      });
    },
  },
};
</script>
